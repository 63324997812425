<template>
    <div>
        <h1>Graderingar {{ currentPeriod }}</h1>
        <div v-if="user.admin" class="my-2">
            <b-form-select v-model="currentPeriod" :options="periods" />
        </div>


        <p><b>Graderingsdatum: </b> {{ plannedPromotionDate }} </p>

        <div v-if="inProgress">
            <b-spinner label="Spinning"></b-spinner>
        </div>

        <!-- Show list of all ranks, if no promotion is selected -->
        <PromotionsRankList v-else-if="!currentPromotion"
                            :ranks="allRanks"
                            :promotions="promotions"
                            @new-promotion="newPromotion"
                            @selected-promotion="setPromotion" />

        <!-- List of potential candidates for selected rank -->
        <div v-else-if="currentPromotion">

            <h3>
                {{ currentPromotion.type === 'prePromotion' ? 'Provgradering' : 'Gradering' }}
                till
                {{ isNaN(currentPromotion.rank) ? currentPromotion.rank : allRanks[currentPromotion.rank-1].text }}
                <RankImage :rank="currentPromotion.rank"/>
            </h3>

            <!-- Add promotion metadata -->
            <PromotionsMetadata :allow-edit="editPromotion"
                     :date="currentPromotion.date"
                     :examiners="currentPromotion.examiners"
                     :instructors="instructors"
                     @selected-date="setDate"
                     @selected-examiners="setExaminers"/>

            <!-- List candidates for pre-promotion -->
            <div v-if="currentPromotion.type == 'prePromotion'">
                <b-list-group v-if="people && people.length > 0">
                    <!-- Header -->
                    <b-list-group-item variant="dark">
                        <b-row>
                            <b-col cols="4"><b>Medlem</b></b-col>
                            <b-col cols="1" class="text-center"><b>Avgift</b></b-col>
                            <b-col cols="1" class="text-right"><b>Pass</b></b-col>
                            <b-col cols="2" class="text-center"><b>Ej godkänd</b></b-col>
                            <b-col cols="2" class="text-center"><b>Godkänd</b></b-col>
                            <b-col cols="2" class="text-center"><b>Bälte</b></b-col>
                        </b-row>
                    </b-list-group-item>

                    <b-list-group-item
                        v-for="person in people"
                        :key="person.id"
                        :disabled="!editPromotion || isApprovedForCurrentRank(person) || isNotApprovedForCurrentRank(person)"
                        @click="showModal(person.memberId)"
                    >
                        <PromotionsCandidateItem :editing="editPromotion"
                         :person="person"
                         :target-rank="currentPromotion.rank"
                         :planned-promotion-date="plannedPromotionDate"
                         :approved-in-current="isApprovedInCurrentPromotion(person)"
                         :not-approved-in-current="isNotApprovedInCurrentPromotion(person)"
                         :approved-for-rank="isApprovedForCurrentRank(person)"
                         :not-approved-for-rank="isNotApprovedForCurrentRank(person)"
                         :beltSize="getSelectedBeltSize(person)"
                         :currentPeriod="currentPeriod"
                         @set-approved="setApproved(person)"
                         @unset-approved="unsetApproved(person)"
                         @set-not-approved="setNotApproved(person)"
                         @unset-not-approved="unsetNotApproved(person)"
                         @belt-size-selected="beltSizeSelected"
                         @click="selectedMemberId=person.memberId"
                        />
                    </b-list-group-item>
                </b-list-group>
                <div v-else> Inga kandidater till denna grad </div>
            </div>

            <div v-if="currentPromotion.type == 'promotion'">
                <b-list-group v-if="readyForPromotion.length > 0">
                    <!-- Header -->
                    <b-list-group-item variant="dark">
                        <b-row>
                            <b-col cols="4"><b>Medlem</b></b-col>
                            <b-col cols="1" class="text-center"><b>Avgift</b></b-col>
                            <b-col cols="1" class="text-right"><b>Pass</b></b-col>
                            <b-col cols="2" class="text-center"><b>Ej godkänd</b></b-col>
                            <b-col cols="2" class="text-center"><b>Godkänd</b></b-col>
                            <b-col cols="2" class="text-center"><b>Bälte</b></b-col>
                        </b-row>
                    </b-list-group-item>

                    <b-list-group-item
                        v-for="person in readyForPromotion"
                        :key="person.id"
                        :disabled="!editPromotion"
                    >
                        <PromotionsCandidateItem :editing="editPromotion"
                         :person="person"
                         :target-rank="currentPromotion.rank"
                         :planned-promotion-date="plannedPromotionDate"
                         :approved-in-current="isApprovedInCurrentPromotion(person)"
                         :not-approved-in-current="isNotApprovedInCurrentPromotion(person)"
                         @set-approved="setApproved(person)"
                         @unset-approved="unsetApproved(person)"
                         @set-not-approved="setNotApproved(person)"
                         @unset-not-approved="unsetNotApproved(person)"
                         @belt-size-selected="beltSizeSelected(person)"
                         @click="selectedMemberId=person.memberId"
                        />
                    </b-list-group-item>
                </b-list-group>
                <div v-else> Inga kandidater till denna grad </div>
            </div>
            <!-- Button panel -->
            <PromotionsButtonPanel
                :editing="editPromotion"
                :showDelete="!!currentPromotion.id"
                @edit="editPromotion = true"
                @save="savePromotion"
                @reset="resetPromotion"
                @delete="deletePromotion"/>

        </div>
        <MemberInfoModal :memberId="selectedMemberId"/>
    </div>
</template>

<script>
 import { mapState } from 'vuex';
 import MemberInfoModal from '@/components/MemberInfoModal.vue'
 import RankImage from '@/components/RankImage.vue'
 import PromotionsButtonPanel from '@/components/PromotionsButtonPanel.vue'
 import PromotionsCandidateItem from '@/components/PromotionsCandidateItem.vue'
 import PromotionsMetadata from '@/components/PromotionsMetadata.vue'
 import PromotionsRankList from '@/components/PromotionsRankList.vue'
 import { rankNames, newRanks } from '@/utils/ranks.js'
 import { db } from '@/firebaseConfig.js'
 import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, setDoc, where } from 'firebase/firestore'


 export default {
     mounted() {
         this.unsubscribe = onSnapshot(query(collection(db, 'promotions')), (querySnapshot) => {
             const ps = []
             querySnapshot.forEach((doc) => {
                 ps.push({ id: doc.id, ...doc.data() })
             })
             this.allPromotions = ps
         })
     },

     unmounted() {
         if (this.unsubscribe) {
             this.unsubscribe()
         }
     },

     components: { MemberInfoModal, RankImage, PromotionsButtonPanel, PromotionsCandidateItem, PromotionsMetadata, PromotionsRankList },

     data() {
         return {
             currentPeriod: 'ht-2024',
             periods: ['vt-2023', 'ht-2023', 'vt-2024', 'ht-2024'],
             allPromotions: [],
             plannedPromotionDate: '2024-12-14',
             groups: [],
             members: [],
             people: [] ,
             currentPromotion: null,
             inProgress: false,
             editPromotion: null,
             editMetadata: true,
             unsubscribe: null,
             selectedMemberId: null
         }
     },

     computed: {
         ...mapState('user', ['user']),
         allRanks() {
             return ['vt-2023', 'ht-2023', 'vt-2024'].includes(this.currentPeriod)
                  ? [...rankNames.slice(1,11).map((name, index) => { return { value: index + 1, text: name }})]
                  : [...newRanks.slice(1,12).map((name) => { return { value: name, text: name }})]
         },
         promotions() {
             return this.allPromotions.filter(p => p.period == this.currentPeriod);
         },
         instructors() {
             return this.members
                        .filter(member => member.functions && member.functions.includes('inst'))
                        .map(i => {
                            const name = `${i.firstName} ${i.lastName}`
                            return {
                                text: name,
                                value: { id: i.id, name: name }
                        }})
                        .sort((a, b) => ('' + a.text).localeCompare(b.text))
         },
         readyForPromotion() {
             return this.people.filter(person => this.isApprovedForCurrentRank(person) || this.isNotApprovedForCurrentRank(person))
         }
     },

     methods: {
         /** Fetch groups for current period if they have not already been fetched */
         async fetchGroups() {
             if (this.groups && this.groups.length) {
                 return // groups already populated
             }
             const groups = []
             const q = query(collection(db, 'groups'), where('period', '==', this.             currentPeriod))
             let groupSnapshot = await getDocs(q)
             groupSnapshot.forEach(doc => {
                 groups.push({id: doc.id, ...doc.data()})
             })
             this.groups = groups;
         },

         /** Fetch all members if they have not already been fetched */
         async fetchMembers() {
             if (this.members && this.members.length) {
                 return // members already populated
             }
             const members = []
             let membersSnapshot = await getDocs(collection(db, 'members'))
             membersSnapshot.forEach(doc => {
                 members.push({id: doc.id, ...doc.data()})
             })
             this.members = members;
         },

         async findCandidatesToRank(rank) {
             this.inProgress = true
             await this.fetchGroups()
             await this.fetchMembers()

             let candidates = []
             switch (rank) {
                 case '6 mon': // Vit-gult
                     candidates = await this.fetchGroupMembersByGroupName('Mon nybörjare')
                     break
                 case '5 mon': // Gul-vitt
                     candidates = await this.fetchGroupMembersByGroupName('Mon gul-vitt')
                     break
                 case '5 kyu': // Gult
                     candidates = await this.fetchGroupMembersByGroupName('Nybörjare')
                     candidates = candidates.concat(await this.fetchGroupMembersByGroupName('Ungdom'))
                     candidates = candidates.filter(m => !m.rank || [1, 2, '5 mon', '4 mon'].includes(m.rank))
                     break
                 case '4 mon': // Vit-orange
                     candidates = await this.fetchGroupMembersByGroupName('Mon gul-vitt')
                     candidates = candidates.filter(m => !m.rank || ['5 mon'].includes(m.rank))
                     break
                 case '3 mon': // Orange-vitt
                     candidates = await this.fetchGroupMembersByGroupName('Mon avanc')
                     candidates = candidates.filter(m => [2, '4 mon'].includes(m.rank))
                     break
                 case '4 kyu': // Orange
                     candidates = await this.fetchGroupMembersByGroupName('Gula och oranga')
                     candidates = candidates.concat(await this.fetchGroupMembersByGroupName('Ungdom'))
                     candidates = candidates.filter(m => [3, 4, 6, '3 kyu', '3 mon', '2, mon'].includes(m.rank))
                     break
                 case '2 mon': // Vit-grönt
                     candidates = await this.fetchGroupMembersByGroupName('Mon avanc')
                     candidates = candidates.concat(await this.fetchGroupMembersByGroupName('Ungdom'))
                     candidates = candidates.filter(m => [3, '3 mon'].includes(m.rank))
                     break
                 case '1 mon': // Grön-vitt
                     candidates = await this.fetchGroupMembersByGroupName('Mon avanc')
                     candidates = candidates.concat(await this.fetchGroupMembersByGroupName('Ungdom'))
                     candidates = candidates.filter(m => [4, '2 mon'].includes(m.rank))
                     break
                 case '3 kyu': // Grönt
                     candidates = await this.fetchGroupMembersByGroupName('Gula och oranga')
                     candidates = candidates.concat(await this.fetchGroupMembersByGroupName('Ungdom'))
                     candidates = candidates.filter(m => [5, 7, '4 kyu', '1 mon'].includes(m.rank))
                     break
                 case '2 kyu': // Blått
                     candidates = await this.fetchGroupMembersByGroupName('Gröna och blå')
                     candidates = candidates.filter(m => [8, '3 kyu'].includes(m.rank))
                     break
                 case '1 kyu': // Brunt
                     candidates = await this.fetchGroupMembersByGroupName('Gröna och blå')
                     candidates = candidates.filter(m => [9, '2 kyu'].includes(m.rank))
                     break
             }
             this.people = candidates
             this.inProgress = false
         },

         async fetchGroupMembersByGroupName(groupName) {
             const groupId = this.groups.find(group => group.name == groupName).id

             const workouts = await this.fetchWorkoutsByGroup(groupId)
             const members = []
             const q = query(collection(db, 'groupMembers'), where('groupId', '==', groupId), orderBy('name'))
             let groupMembersSnapshot = await getDocs(q)
             groupMembersSnapshot.forEach(doc => {
                 let gm = { id: doc.id, ...doc.data() }
                 if (!(gm.isProspect || gm.isInstructor)) {
                     let m = this.members.find(m => m.id == gm.memberId)
                     members.push({ ...m, ...gm, workouts: this.workoutsForMember(workouts, gm.id) })
                 }
             })
             return members.filter(m => !m.isInstructor)
         },

         async fetchWorkoutsByGroup(groupId) {
             const workouts = []
             const q = query(collection(db, 'workouts'), where('groupId', '==', groupId))
             let workoutsSnapshot = await getDocs(q)
             workoutsSnapshot.forEach(doc => {
                 workouts.push( { id: doc.id, ...doc.data() })
             })
             return workouts
         },

         async showModal(memberId) {
             this.selectedMemberId = memberId
             this.$bvModal.show('member-info')
         },

         workoutsForMember(workouts, groupMemberId) {
             return workouts.filter(w => w.attendees.includes(groupMemberId)).length
         },

         promotionsByRank(rank) {
             return this.promotions
                        .filter(p => p.rank == rank)
                        .sort((p1, p2) => p1.date > p2.date)
         },

         setDate(date) {
             this.currentPromotion.date = date
         },

         setExaminers(examiners) {
             this.currentPromotion.examiners = examiners
         },

         async newPromotion(rank, type) {
             this.editPromotion = true
             this.currentPromotion = {
                 period: this.currentPeriod,
                 rank: rank,
                 type: type,
                 date: this.dateToString(new Date()),
                 examiners: [],
                 approved: [],
                 notApproved: [],
                 beltSizes: []
             }
             await this.findCandidatesToRank(rank)
         },

         resetPromotion() {
             this.currentPromotion = null
         },

         async setPromotion(promotion) {
             this.editPromotion = false
             this.currentPromotion = promotion
             await this.findCandidatesToRank(promotion.rank)
         },

         async savePromotion() {
             if (this.currentPromotion.id) {
                 await setDoc(doc(db, 'promotions', this.currentPromotion.id), this.currentPromotion)
             } else {
                 const docRef = await addDoc(collection(db, 'promotions'), this.currentPromotion)
                 this.currentPromotion.id = docRef.id
             }
             this.editPromotion = false
         },

         async deletePromotion() {
             if (this.currentPromotion.id) {
                 if (confirm("Vill du ta bort provgraderingen?")) {
                     await deleteDoc(doc(db, 'promotions', this.currentPromotion.id))
                     this.resetPromotion()
                 }
             }
         },

         isApprovedInCurrentPromotion(person) {
             return this.currentPromotion.approved.some(p => p.memberId === person.memberId);
         },

         isNotApprovedInCurrentPromotion(person) {
             return this.currentPromotion.notApproved.some(p => p.memberId === person.memberId);
         },

         isApprovedForCurrentRank(person) {
             return this.promotionsByRank(this.currentPromotion.rank)
                        .filter(prom => prom.id !== this.currentPromotion.id)
                        .some(prom => prom.approved.some(p => p.memberId === person.memberId));
         },

         isNotApprovedForCurrentRank(person) {
             return this.promotionsByRank(this.currentPromotion.rank)
                        .filter(prom => prom.id !== this.currentPromotion.id)
                        .some(prom => prom.notApproved.some(p => p.memberId === person.memberId));
         },

         setApproved(person) {
             this.currentPromotion.approved.push({ memberId: person.memberId, name: person.name })
         },

         unsetApproved(person) {
             var index = this.currentPromotion.approved.findIndex(p => p.memberId === person.memberId)
             if (index > -1) {
                 this.currentPromotion.approved.splice(index, 1)
             }
         },

         setNotApproved(person) {
             this.currentPromotion.notApproved.push({ memberId: person.memberId, name: person.name })
         },

         unsetNotApproved(person) {
             var index = this.currentPromotion.notApproved.findIndex(p => p.memberId === person.memberId)
             if (index > -1) {
                 this.currentPromotion.notApproved.splice(index, 1)
             }
         },
         getSelectedBeltSize(person) {
             const selection = this.currentPromotion.beltSizes.find(selection => selection.memberId === person.memberId);
             return selection ? selection.size : null
         },

         beltSizeSelected(selection) {
             var index = this.currentPromotion.beltSizes.findIndex(bs => bs.memberId === selection.memberId)
             if (index > -1) {
                 this.currentPromotion.beltSizes.splice(index, 1)
             }
             this.currentPromotion.beltSizes.push(selection)

         },
         dateToString(date) {
             return date.toISOString().slice(0, 10)
         }
     }
 }
</script>
