<template>
  <div>
    <b-modal
      id="member-info"
      ok-only
      ok-title="Stäng"
      >
      <template
        v-if="member"
        #modal-header
        >
        <h5>{{ name }}</h5> <RankImage :rank="member.rank" />
      </template>
      <b-spinner v-if="loading"></b-spinner>
      <div v-else>
        Senaste gradering:
        <span v-if="latestPromotion">{{ latestPromotion }}</span>
        <span v-else>saknar info</span>
        <br/>
        Närvaro per termin och grupp:
        <ul v-if="workoutsByGroup">
          <li v-for="group in groups" :key="group.id">
            {{ groupDescriptionById(group.id) }}: {{ workoutsByGroup.get(group.id).length }}
          </li>
        </ul> 
      </div>
    </b-modal>
  </div>
</template>

<script>
import { db, documentId } from '@/firebaseConfig';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import RankImage from '@/components/RankImage.vue'
import { periodComparator } from '@/utils/period.js'

export default {
  components: { RankImage },
  props: {
    memberId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      member: null,
      groupMembers: [],
      groups: [],
      workoutsByGroup: new Map() 
    }
  },
  computed: {
    name() {
      return this.member
        ? `${this.member.firstName} ${this.member.lastName}`
        : ''
    },
    latestPromotion() {
      return this.member && this.member.promotions
        ? this.member.promotions.toSorted(this.promotionComparator).reverse()[0].date
        : null 
    }
  },
  watch: {
    async memberId(newMemberId) {
      this.loading = true
      this.member = await this.fetchMemberInfo(newMemberId)
      this.groupMembers = await this.fetchGroupMembersByMember(newMemberId)
      this.groups = await this.fetchRelevantGroupsByGroupIds(this.groupMembers.map(m=>m.groupId))
      this.workoutsByGroup = await this.fetchWorkoutHistory(this.groupMembers)
      this.loading = false
    }
  },
  methods: {
    async fetchMemberInfo(memberId) {
      if (memberId) {
        const mRef = doc(db, 'members', memberId)
        try {
        const mSnap = await getDoc(mRef)
          if (mSnap.exists()) {
            return mSnap.data()
          }
        } catch (e) {
          console.error('Error fetching member', e)
        }
      }
      return null
    },

    async fetchGroupMembersByMember(memberId) {
      const result = []
      let q = query(collection(db, 'groupMembers'), where('memberId', '==', memberId))
      try {
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          result.push({ id: doc.id, ...doc.data() })
        })
      } catch (e) {
        console.log('Error fetching group members', e)
      }
      return result
    },

    async fetchRelevantGroupsByGroupIds(groupIds) {
      const result = []
      const q = query(collection(db, 'groups'),
                where(documentId(), 'in', groupIds))
      try {
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          if (['adults', 'youth'].includes(doc.data().category)) {
            result.push({ id: doc.id, ...doc.data() })
          }
        })
      } catch (e) {
        console.log('Error fetching groups', e)
      }
      return result.sort(this.groupComparator).reverse()
    },

    async fetchWorkoutHistory(groupMembers) {
      const workoutsByGroup = new Map()
      try {
        for (const groupMember of groupMembers) {
          const workouts = []
          const q = query(collection(db, 'workouts'),
                          where('groupId', '==', groupMember.groupId),
                          where('attendees', 'array-contains', groupMember.id)
                         ) 
          const querySnapshot = await getDocs(q)
          
          querySnapshot.forEach((doc) => {
            workouts.push({ id: doc.id, ...doc.data() })
          })
          workoutsByGroup.set(groupMember.groupId, workouts)
        } 
      } catch (e) {
        console.log('Error fetching workouts', e)
      }
      return workoutsByGroup
    },

    groupDescriptionById(groupId) {
      const group = this.groups.find(g => g.id === groupId)
      return group
        ? `${group.period} - ${group.name}`
        : ''
    },

    /* Order groups by period */
    groupComparator(a, b) {
      return periodComparator(a.period, b.period)
    },

    /* Order promotions by date */
    promotionComparator(a, b) {
      return a.date === b.date
        ? 0
        : (a.date > b.date ? 1 : -1)
    }
  }

}
</script>
