<template>
  <b-button :variant="buttonVariant" pill>
    {{ promotionDate }}
    <b-badge pill variant="success" class="mx-2">{{ numberOfApproved }}</b-badge>
  </b-button>
</template>

<script>
export default {
  props: {
    promotion: {
      type: Object
    }
  },
  computed: {
    buttonVariant() {
      return this.promotion.type === 'prePromotion' ? 'outline-secondary' : 'secondary'
    },
    promotionDate() {
      return this.promotion.date
    },
    numberOfApproved() {
      return this.promotion.approved.length
    }
  }
}
</script>
