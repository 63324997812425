<template>
  <div v-if="editing"
       class="my-2 d-flex justify-content-between align-items-center">
    <div>
      <b-button class="mx-1" variant="primary" @click="$emit('save')">Spara</b-button>
      <b-button class="mx-1" @click="$emit('reset')">Avbryt</b-button>
    </div>
    <div>
      <b-button v-if="showDelete" class="mx-1" variant="danger" @click="$emit('delete')">Radera</b-button>
    </div>
  </div>
  <div class="my-2 px-2" v-else>
    <b-button class="mx-1" variant="primary" @click="$emit('edit')">Ändra</b-button>
    <b-button class="mx-1" @click="$emit('reset')">Stäng</b-button>
  </div>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean
    },
    showDelete: {
      type: Boolean
    }
  },
  emits: ['edit', 'reset', 'save', 'delete']
}
</script>
