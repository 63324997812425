const rankNames = [
  "Vit", //        0
  "Gul-vit", //    1
  "Orange-vit", // 2
  "Grön-vit", //   3
  "Blå-vit", //    4
  "Brun-vit", //   5
  "Gul", //        6
  "Orange", //     7
  "Grön", //       8
  "Blå", //        9
  "Brun", //       10
  "1 dan", //      11
  "2 dan", //      12
  "3 dan", //      13
  "4 dan", //      14
  "5 dan", //      15
  "6 dan", //      16
  "7 dan", //      17
  "8 dan", //      18
  "9 dan", //      19
  "10 dan" //      20
];

const newRanks = [
  "",
  "6 mon", // vit-gult
  "5 mon", // gul-vitt
  "5 kyu", // gult
  "4 mon", // vit-orange
  "3 mon", // orange-vitt
  "4 kyu", // orange
  "2 mon", // vit-grönt
  "1 mon", // grön-vitt
  "3 kyu", // grönt
  "2 kyu", // blått
  "1 kyu", // brunt
  "1 dan",
  "2 dan",
  "3 dan",
  "4 dan",
  "5 dan",
  "6 dan",
  "7 dan",
  "8 dan",
  "9 dan",
  "10 dan"
];

export { rankNames, newRanks };
