<template>
  <div>
    <div v-if="!allowEdit || !expanded">
      <b>Datum:</b> {{ date }}<br/>
      <b>Examinator{{ examiners.length > 1 ? 'er' : '' }}:</b> {{ metadata }}
      <font-awesome-icon v-if="allowEdit"
                         :icon="['fas', 'caret-down']"
                         @click="expanded = true"></font-awesome-icon>
    </div>

    <div class="my-2" v-else>
      <b-card>
        <b-form>
          <!-- Date -->
          <b-form-group label="Datum:">
            <b-form-datepicker id="datepicker"
                               :value="date"
                               @input="selectedDate"
                               locale="sv-SE"
                               start-weekday="1"
                               class="mb-2">
            </b-form-datepicker>
          </b-form-group>

          <!-- Examiners -->
          <B-form-group label="Examinator(er):">
            <b-form-select :value="examiners" :options="instructors" @change="selectedExaminers" multiple>{{</b-form-select>
          </b-form-group>
        </b-form>
        <b-button @click="expanded = false">Dölj</b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allowEdit: {
      type: Boolean
    },
    date: {
      type: String
    },
    examiners: {
      type: Array
    },
    instructors: {
      type: Array
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    metadata() {
      const examiners = this.examiners
      return examiners.length ? examiners.map(ex => ex.name).join(', ') : ''
    }
  },
  methods: {
    selectedDate(date) {
      this.$emit('selected-date', date)
    },
    selectedExaminers(value) {
      console.log('emitting selected examiners', value)
      this.$emit('selected-examiners', value)
    }
  }
}
</script>
