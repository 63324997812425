function year(period) {
  return period.substring(3)
}

function semester(period) {
  return period.substring(0,2)
}

/* Sort period strings on the form 'vt-2023' */
function periodComparator(a, b) {
  const yearDiff = year(a) - year(b) 
  if (yearDiff != 0) {
    return yearDiff
  }
  const semesterA = semester(a)
  const semesterB = semester(b)
  if (semesterA === semesterB) {
    return 0
  }
  return semesterA === 'vt'
    ? -1
    : 1
}

export {
  periodComparator
}
